import * as config from '../config'
import * as firebase from "firebase";

export const __DEV__ = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

class DataService {
    constructor() {
        this.firebase = firebase.initializeApp(config.FIREBASE_CONFIG);
        this.firestore = this.firebase.firestore();
        this.functions = this.firebase.functions();
        this.user = [];
    }

    generateRandomIDForCollection(collection) {
        return firebase.firestore().collection(collection).doc().id;
    }

    async storeUserCampaign(info, id) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await fetch('https://pro.ip-api.com/json/?key=cjbFUan7D1Wy7OQ&fields=66846719');
                const data = await response.json();

                info.ipInfo = data;
                info.date = firebase.firestore.FieldValue.serverTimestamp();
                if (!!data && !!data.query) {
                    info.ipv4 = data.query;
                }

                await firebase.firestore().collection('clicks').doc(id).set(info);
                resolve({id: id});
            } catch (error) {
                __DEV__ && console.log(`[storeUserCampaign] Error ${error}`);
                resolve({id: ''});
            }
        });
    }

    async retrieveDynamicLink(shortURL, z) {
        return new Promise(async (resolve, reject) => {
            let defaultURL = !!z ? (!!shortURL && shortURL.includes('?') ? `${shortURL}&z=${z}` : `${shortURL}?z=${z}`) : shortURL;
            try {
                const response = await fetch(`https://europe-west1-realfooding-app.cloudfunctions.net/action_resolve_dynamic_link`, {
                    method: 'POST',
                    body: JSON.stringify({
                        shortURL: shortURL.replace(/https\:\/\/(.+)\.myrealfood.app\//, 'https://link.myrealfood.app/'),
                        z: z
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Access-Control-Allow-Origin": "*"
                    },
                });
                const data = await response.json();
                defaultURL = data.url;
            } catch (error) {
            }
            resolve({url: defaultURL});
        });
    }
}

export default new DataService();
