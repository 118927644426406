import React from 'react';

import './App.css';
import {Redirect, Route, Switch, withRouter} from 'react-router'

import Get from "./common/Get";

import ReactPixel from 'react-facebook-pixel';


const app = function App() {

	if (!!window && !!window.location && !!window.location.href &&
		(window.location.href.includes('/valorar/') ||
			window.location.href.includes('/rate') ||
			window.location.href.includes('/plus/') ||
			window.location.href.includes('/download/') ||
			window.location.href.includes('/get/') ||
			window.location.href.includes('/descargar/') ||
			window.location.href.includes('/s/'))) {

		ReactPixel.init('297577238442174', {}, {
		  autoConfig: true,
		  debug: false
		});
		ReactPixel.track('campaign_view', {
			location: window.location.href
		});
		window.ttq.track('campaign_view', {
			location: window.location.href
		});
		window.gtag('event', 'campaign_view', {
			location: window.location.href
		});

	}

	return (
		<Switch>
			<Route path='/valorar/1'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=RATE&utm_country=ES&utm_campaign=ACTION&utm_source=ig&utm_medium=stories&utm_content=&utm_term=redirect&utm_category=intern_myrealfood&utm_metadata=https://myrealfood.app/valorar"/>
			</Route>
			<Route path='/valorar/2'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=RATE&utm_country=ES&utm_campaign=ACTION&utm_source=ig&utm_medium=stories&utm_content=&utm_term=redirect&utm_category=intern_carlosriosq&utm_metadata=https://myrealfood.app/valorar"/>
			</Route>
			<Route path='/valorar/3'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=RATE&utm_country=ES&utm_campaign=ACTION&utm_source=ig&utm_medium=stories&utm_content=&utm_term=redirect&utm_category=intern_realfooding&utm_metadata=https://myrealfood.app/valorar"/>
			</Route>
			<Route path='/rate'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=RATE&utm_country=UKIE&utm_campaign=ACTION&utm_source=ig&utm_medium=stories&utm_content=&utm_term=redirect&utm_category=intern_myrealfood&utm_metadata=https://myrealfood.app/valorar"/>
			</Route>
			<Route path='/plus/w1'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DL&utm_country=ES&utm_campaign=ACTION&utm_source=ig&utm_medium=stories&utm_content=plus&utm_term=redirect&utm_category=intern_myrealfood&utm_metadata=https://myrealfood.app/plus/"/>
			</Route>
			<Route path='/plus/w2'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DL&utm_country=ES&utm_campaign=ACTION&utm_source=ig&utm_medium=stories&utm_content=plus&utm_term=redirect&utm_category=intern_carlosriosq&utm_metadata=https://myrealfood.app/plus/"/>
			</Route>
			<Route path='/plus/w3'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DL&utm_country=ES&utm_campaign=ACTION&utm_source=ig&utm_medium=stories&utm_content=plus&utm_term=redirect&utm_category=intern_realfooding&utm_metadata=https://myrealfood.app/plus/"/>
			</Route>
			<Route path='/plus/1'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DL&utm_country=ES&utm_campaign=ACTION&utm_source=ig&utm_medium=stories&utm_content=plus&utm_term=redirect&utm_category=intern_myrealfood&utm_metadata=https://link.myrealfood.app/share/bx9LAaqoc65Bz9Xz5"/>
			</Route>
			<Route path='/plus/2'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DL&utm_country=ES&utm_campaign=ACTION&utm_source=ig&utm_medium=stories&utm_content=plus&utm_term=redirect&utm_category=intern_carlosriosq&utm_metadata=https://link.myrealfood.app/share/bx9LAaqoc65Bz9Xz5"/>
			</Route>
			<Route path='/plus/3'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DL&utm_country=ES&utm_campaign=ACTION&utm_source=ig&utm_medium=stories&utm_content=plus&utm_term=redirect&utm_category=intern_realfooding&utm_metadata=https://link.myrealfood.app/share/bx9LAaqoc65Bz9Xz5"/>
			</Route>
			<Route path='/download/youtube'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=GLOBAL&utm_campaign=INSTALLS&utm_source=yt&utm_medium=&utm_content=&utm_term=download&utm_category=intern_myrealfood&utm_metadata="/>
			</Route>
			<Route path='/get/'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=GLOBAL&utm_campaign=INSTALLS&utm_source=&utm_medium=&utm_content=&utm_term=download&utm_category=intern_global&utm_metadata="/>
			</Route>
			<Route path='/download/global'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=GLOBAL&utm_campaign=INSTALLS&utm_source=ig&utm_medium=&utm_content=&utm_term=download&utm_category=intern_global&utm_metadata="/>
			</Route>
			<Route path='/descargar/1'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=ES&utm_campaign=INSTALLS&utm_source=ig&utm_medium=&utm_content=&utm_term=download&utm_category=intern_myrealfood&utm_metadata="/>
			</Route>
			<Route path='/descargar/ig/1'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=ES&utm_campaign=INSTALLS&utm_source=ig&utm_medium=&utm_content=&utm_term=download&utm_category=intern_myrealfood&utm_metadata="/>
			</Route>
			<Route path='/descargar/tk/1'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=ES&utm_campaign=INSTALLS&utm_source=tk&utm_medium=&utm_content=&utm_term=download&utm_category=intern_myrealfood&utm_metadata="/>
			</Route>
			<Route path='/descargar/tw/1'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=ES&utm_campaign=INSTALLS&utm_source=tw&utm_medium=&utm_content=&utm_term=download&utm_category=intern_myrealfood&utm_metadata="/>
			</Route>
			<Route path='/descargar/yt/1'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=ES&utm_campaign=INSTALLS&utm_source=yt&utm_medium=&utm_content=&utm_term=download&utm_category=intern_myrealfood&utm_metadata="/>
			</Route>
			<Route path='/descargar/pt/1'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=ES&utm_campaign=INSTALLS&utm_source=pt&utm_medium=&utm_content=&utm_term=download&utm_category=intern_myrealfood&utm_metadata="/>
			</Route>
			<Route path='/descargar/2'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=ES&utm_campaign=INSTALLS&utm_source=ig&utm_medium=&utm_content=&utm_term=download&utm_category=intern_carlosriosq&utm_metadata="/>
			</Route>
			<Route path='/descargar/ig/2'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=ES&utm_campaign=INSTALLS&utm_source=ig&utm_medium=&utm_content=&utm_term=download&utm_category=intern_carlosriosq&utm_metadata="/>
			</Route>
			<Route path='/descargar/tk/2'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=ES&utm_campaign=INSTALLS&utm_source=tk&utm_medium=&utm_content=&utm_term=download&utm_category=intern_carlosriosq&utm_metadata="/>
			</Route>
			<Route path='/descargar/3'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=ES&utm_campaign=INSTALLS&utm_source=ig&utm_medium=&utm_content=&utm_term=download&utm_category=intern_realfooding&utm_metadata="/>
			</Route>
			<Route path='/descargar/ig/3'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=ES&utm_campaign=INSTALLS&utm_source=ig&utm_medium=&utm_content=&utm_term=download&utm_category=intern_realfooding&utm_metadata="/>
			</Route>
			<Route path='/descargar/tk/3'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=ES&utm_campaign=INSTALLS&utm_source=tk&utm_medium=&utm_content=&utm_term=download&utm_category=intern_realfooding&utm_metadata="/>
			</Route>
			<Route path='/download/ukie'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=UKIE&utm_campaign=INSTALLS&utm_source=ig&utm_medium=&utm_content=&utm_term=download&utm_category=intern_global&utm_metadata="/>
			</Route>
			<Route path='/download/ig/ukie'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=UKIE&utm_campaign=INSTALLS&utm_source=ig&utm_medium=&utm_content=&utm_term=download&utm_category=intern_global&utm_metadata="/>
			</Route>
			<Route path='/download/tk/ukie'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=UKIE&utm_campaign=INSTALLS&utm_source=tk&utm_medium=&utm_content=&utm_term=download&utm_category=intern_global&utm_metadata="/>
			</Route>
			<Route path='/download/1'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=UKIE&utm_campaign=INSTALLS&utm_source=ig&utm_medium=&utm_content=&utm_term=download&utm_category=intern_myrealfood&utm_metadata="/>
			</Route>
			<Route path='/download/ig/1'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=UKIE&utm_campaign=INSTALLS&utm_source=ig&utm_medium=&utm_content=&utm_term=download&utm_category=intern_myrealfood&utm_metadata="/>
			</Route>
			<Route path='/download/tk/1'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=UKIE&utm_campaign=INSTALLS&utm_source=tk&utm_medium=&utm_content=&utm_term=download&utm_category=intern_myrealfood&utm_metadata="/>
			</Route>
			<Route path='/download/2'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=UKIE&utm_campaign=INSTALLS&utm_source=ig&utm_medium=&utm_content=&utm_term=download&utm_category=intern_carlosriosq&utm_metadata="/>
			</Route>
			<Route path='/download/ig/2'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=UKIE&utm_campaign=INSTALLS&utm_source=ig&utm_medium=&utm_content=&utm_term=download&utm_category=intern_carlosriosq&utm_metadata="/>
			</Route>
			<Route path='/download/tk/2'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=UKIE&utm_campaign=INSTALLS&utm_source=tk&utm_medium=&utm_content=&utm_term=download&utm_category=intern_carlosriosq&utm_metadata="/>
			</Route>
			<Route path='/download/3'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=UKIE&utm_campaign=INSTALLS&utm_source=ig&utm_medium=&utm_content=&utm_term=download&utm_category=intern_realfooding&utm_metadata="/>
			</Route>
			<Route path='/download/ig/3'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=UKIE&utm_campaign=INSTALLS&utm_source=ig&utm_medium=&utm_content=&utm_term=download&utm_category=intern_realfooding&utm_metadata="/>
			</Route>
			<Route path='/download/tk/3'>
				<Redirect
					to="https://campaigns.myrealfood.app/?utm_id=DOWNLOAD&utm_country=UKIE&utm_campaign=INSTALLS&utm_source=tk&utm_medium=&utm_content=&utm_term=download&utm_category=intern_realfooding&utm_metadata="/>
			</Route>

			<Route path='/s/DESCUENTO/ig/mrf'>
				<Redirect
					to="https://c.myrealfood.app/?u=PLUS&c=ES&a=CONVERSION&s=ig&m=&t=&d=offer&g=intern_myrealfood&e=VERANO"/>
			</Route>
			<Route path='/s/DESCUENTO/tk/mrf'>
				<Redirect
					to="https://c.myrealfood.app/?u=PLUS&c=ES&a=CONVERSION&s=tk&m=&t=&d=offer&g=intern_myrealfood&e=VERANO"/>
			</Route>
			<Route path='/s/DESCUENTO/ig/cr'>
				<Redirect
					to="https://c.myrealfood.app/?u=PLUS&c=ES&a=CONVERSION&s=ig&m=&t=&d=offer&g=intern_carlosriosq&e=VERANO"/>
			</Route>
			<Route path='/s/DESCUENTO/ig/rf'>
				<Redirect
					to="https://c.myrealfood.app/?u=PLUS&c=ES&a=CONVERSION&s=ig&m=&t=&d=offer&g=intern_realfooding&e=VERANO"/>
			</Route>
			<Route path='/s/DISCOUNT/ig/mrf'>
				<Redirect
					to="https://c.myrealfood.app/?u=PLUS&c=GB&a=CONVERSION&s=ig&m=&t=&d=offer&g=intern_myrealfood&e=SUMMERGB"/>
			</Route>

			<Route path='/'>
				<Get/>
			</Route>
			<Route path='*'>
				<Redirect to='/'/>
			</Route>
		</Switch>
	);
}

export default withRouter(app);
